<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-autocomplete  solo clearable v-model="cust_id" :items="customers" item-value="cust_id" item-text="cust_name" label="Customer" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3">
                                            <v-menu ref="modal" v-model="modal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo v-model="date_from" label="Start Periode" persistent-hint prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" class="ma-0 pa-0" hide-details=true></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from" type="month" no-title @input="modal = false"></v-date-picker>
                                </v-menu>
                              </v-col>
                               <v-col cols="12" xs="12" sm="3" md="3" >
                                <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field solo v-model="date_to" label="End Periode" persistent-hint prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" class="ma-0 pa-0" hide-details=true></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        type="month"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                             <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="getPullData()">Search</v-btn>
                            </v-col>
                             <!-- <v-col cols="12" xs="12" sm="5" md="1">
                                <v-btn class="mr-2" color="error" elevation="2" large @click="getPullData()">Search</v-btn>
                            </v-col> -->
                            <v-col cols="12" class="mt-10">
                            <v-card  color="grey lighten-5" class="pa-md-5 bg_card">  
                            <h5 class="font-weight: 900; text-darken-3 white-text">SUMMARY</h5>
                            <!-- <h6 class="cyan-text text-darken-3 m-0">Summary (Year to Year)</h6> -->
                            <div id="chartContainer1" class="hahe" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>

        </v-row>
    </v-container>
</template>
<script>
  export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'SMS',
                disabled: false,
                href: '/admin/sms',
                },
                {
                text: 'Credit Limit Usage',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Summary Credit Limit Usage',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            //  offices: [{
            //     office_id : 'Sunrise Steel',
            //     office : 'Sunrise Steel'
            // }],
            // office: 'Sunrise Steel',
            customers: [],
            tab: 'tab-1',
            tab: 'tab-1',
            date_from: '',
            modal: false,
            cust_id: '',
            date_to: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            chartContainer1:[],
            modal_to: false,
            speed: null,
            dashboardContent: null,
            x: window.matchMedia("(max-width: 991px)"),
            months: [
                'Jan', 'Feb', 'Mar', 'Apr', 'May',
                'Jun', 'Jul', 'Aug', 'Sep',
                'Oct', 'Nov', 'Dec'
            ],
            subyear: '',
            year: ''
        }
    },
    mounted(){
        // this.getDashboard()
        this.getCustomer()
    },
    search(){
        this.getPullData()
    },
    methods:{
        getCustomer(){
            axios.get(`${process.env.VUE_APP_URL}/api/starcon/finance/credit_limit_and_usage/getCust`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.customers = res.data.data
            });
        },
        async getPullData(){
            this.loading = true

            if (this.cust_id === '' || this.date_from === '' || this.date_to === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Fill Customer, Start Date & End Date',
                    visible: true
                };

                this.$store.dispatch('setOverlay', false)  
                return false
            } else {
                var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 8).replace(/-/g,"") : ''
            await axios.post(`${process.env.VUE_APP_URL}/api/starcon/finance/credit_limit_and_usage`, 
                {
                    'cust_id': this.cust_id,
                    start: start_date ? start_date : "",
                    end: end_date ? end_date : ""
                }, 
                { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                }
            )
            .then(res => {
                
                var dataPoints1 = [];
                var dataPoints2 = [];

                for (var i = 0; i < res.data.data.length; i++) {
                    dataPoints1.push({ label: res.data.data[i].periode, y: res.data.data[i].amt_credit_limit/1000000 });
                    dataPoints2.push({ label: res.data.data[i].periode, y: res.data.data[i].order_amt/1000000 });

                }
                //console.log(dataPoints1)
                console.log('dataPoints1:', dataPoints1);
                console.log('dataPoints2:', dataPoints2);
                this.renderChart1(dataPoints1, dataPoints2);
                
                this.$store.dispatch('setOverlay', false)
        
            })
            }

            
        },
        // async getDashboard(){
        //     this.$store.dispatch('setOverlay', true)

        //     await axios.get(`${process.env.VUE_APP_URL}/api/sr/diagram/CashflowGraph`, { 
        //         headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        //     })
        //     .then(res => {

        //         //console.log(res.data);

        //         var dataPoints1 = [];
        //         var dataPoints2 = [];

        //         for (var i = 0; i < res.data.length; i++) {
        //             //console.log(res.data[i].cash_in);
        //             dataPoints1.push({ label: res.data[i].bulan + ' ' + res.data[i].tahun, y: res.data[i].cash_in/1000000 });
        //             dataPoints2.push({ label: res.data[i].bulan + ' ' + res.data[i].tahun, y: res.data[i].cash_out/1000000 });

        //         }

        //         this.renderChart1(dataPoints1, dataPoints2);

        //         this.$store.dispatch('setOverlay', false)

        //     })
        // },
        renderChart1(datapoints1, datapoints2)
        {

            var chart1 = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                axisX:{
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    }
                },
                axisY: {
                    title: "IDR x1.000.000",
                    titleFontSize: 24,
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                },
                legend: {
                    fontColor: "red",
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                toolTip:{
                    shared:true
                },
                data: [{
                    type: "line",
                    indexLabel: "{y}",
                    name: 'Credit Limit',
                    indexLabelFontSize: 12,
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    yValueFormatString: "###,###",
                    indexLabelFontColor: "white"
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    name: 'Credit Usage',
                    indexLabelFontSize: 12,
                    showInLegend: true,
                    indexLabelPlacement: "inside",
                    indexLabelOrientation: "vertical",
                    yValueFormatString: "###,###",
                    indexLabelFontColor: "white"
                }]
            });

            chart1.options.data[0].dataPoints = datapoints1;
            chart1.options.data[1].dataPoints = datapoints2;
            var x =  window.matchMedia("(max-width: 991px)");
            if (x.matches) {
                for(var i = 0; i < chart1.options.data.length; i++){
                    chart1.options.data[i].indexLabelFontSize = 6;
                }
                chart1.render();
            }

            chart1.render();

        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        }
    },
  }
</script>
<style scoped>
.hahe{
    background-color: white;
}
</style>